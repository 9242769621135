
import './App.css';
import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

import Grid from '@mui/material/Grid';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import emailjs from '@emailjs/browser';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import equip1 from './assets/equip1.png'
import equip2 from './assets/equip2.png'
import equip3 from './assets/equip3.png'
import equip4 from './assets/equip4.png'
import equip5 from './assets/equip5.png'
import equip6 from './assets/equip6.png'
import equip7 from './assets/equip7.png'
import equip8 from './assets/equip8.png'


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function TabPanel2(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function a12yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function Home() {

  const [valueBottom, setValueBottom] = React.useState(0);
  const [valueTabOne, setValueTabOne] = React.useState(0);
  const [valuePoliticas, setValuePoliticas] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showAlert, setShowAlert] = React.useState<boolean>(false);

  const schema = yup.object({
    nombre: yup.string().required('Este campo es obligatorio'),
    apellido: yup.string().required('Este campo es obligatorio'),
    telefono: yup.string().required('Este campo es obligatorio'),
    email: yup.string().required('Este campo es obligatorio'),
    mensaje: yup.string().required('Este campo es obligatorio'),
  }).required();
  
  const form = React.useRef();

  type Inputs = {
    nombre: string,
    apellido: string,
    telefono: string,
    email: string,
    mensaje: string,
  };

  const { control, handleSubmit, formState:{ errors }, register } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (): void => {
    enviarCorreo()
  }

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  async function enviarCorreo(): Promise<void> {
    if (form.current) {
      await emailjs.sendForm('service_jxw8ond', 'template_6epl234', form.current, 'E1lvRjQvuPbKKLTbC')
      .then((result) => {
        console.log(result);
          console.log(result.text);
          setShowAlert(true);
          return true
      }, (error) => {
          console.log(error.text);
          return false
      });
    }
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <main>
      
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className='hideElementsForTablets hideElementsForDesktop hideElementsForBig'
        >
          <Grid container style={{ backgroundColor: 'white', marginTop: 30, width: '85%' }}>
            <Grid item xs={10}>
              <div className='logoEnv'></div>
            </Grid>
            <Grid item xs={1}>
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2, marginTop: 1.5 }}
                  aria-controls={open ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                  <MenuIcon></MenuIcon>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>

        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleClose}>
              <a style={{ textDecoration: 'none', color: 'black' }} href="#">Home</a>
            </MenuItem>
            
            <MenuItem onClick={handleClose} >
              <a style={{ textDecoration: 'none', color: 'black' }} href="#otro-elemento">Politicas</a>
            </MenuItem>

            <MenuItem onClick={handleClose} href="#servicio">
              <a style={{ textDecoration: 'none', color: 'black' }} href="#servicio">Servicios</a>
            </MenuItem>

            <MenuItem onClick={handleClose} href="#contacto">
              <a style={{ textDecoration: 'none', color: 'black' }} href="#contacto">Contactanos</a>
            </MenuItem>

            <MenuItem onClick={handleClose} href="#empresa">
              <a style={{ textDecoration: 'none', color: 'black' }} href="#empresa">Empresa</a>
            </MenuItem>
        </Menu>

        <BottomNavigation
          className='hideElements'
          style={{ marginTop: 30, width: '83%', height: 55 }}
          showLabels
          value={valueBottom}
          onChange={(event, newValue) => {
            setValueBottom(newValue);
          }}
        >
          <BottomNavigationAction icon={ <div className='logoEnv'></div> } />
          <BottomNavigationAction style={{ fontSize: 14 }} label="Home" />
          <BottomNavigationAction style={{ fontSize: 14 }} label="Politicas" href="#otro-elemento" />
          <BottomNavigationAction style={{ fontSize: 14 }} label="Servicios" href="#servicio" />
          <BottomNavigationAction style={{ fontSize: 14 }} label="Contactanos" href="#contacto" />
          <BottomNavigationAction style={{ fontSize: 14 }} label="Empresa" href="#empresa" />
        </BottomNavigation>
        
        <BottomNavigation
          className='hideElements hideElementsForTablets hideElementsForDesktop hideElementsForBig'
          showLabels
          style={{ width: '20%', }}
          value={valueBottom}
          onChange={(event, newValue) => {
            setValueBottom(newValue);
          }}
        >
          <BottomNavigationAction icon={ <div className='logoEnv'></div> } />
          <BottomNavigationAction label="Home" />
          <BottomNavigationAction href="#otro-elemento" label="Politicas" />
          <BottomNavigationAction href="#servicio" label="Servicios" />
          <BottomNavigationAction href="#contacto" label="Contactanos" />
          <BottomNavigationAction href="#empresa" label="Empresa" />
        </BottomNavigation>
        
        <div className='backOne'></div>

        {/* esta tab es para pantallas mas peque;as de 430px */}
        <Grid item className='forTablets hideElementsForDesktop hideElementsForBig' xs={12} md={12} lg={12} xl={12}>
          <Box alignContent={'center'}>
            <TabPanel2 value={valueTabOne} index={0}>
              <Card variant="outlined" sx={{  backgroundColor: 'transparent', border: 0 }}>
                <div className='backTwo'></div>
                <Grid style={{ backgroundColor: '#292929', marginTop: -90, marginLeft: 30, position: 'absolute', zIndex: 1100, width: 250 }}>
                  <Typography fontSize={10} fontWeight={400} variant="body2" color="white" padding={2}>
                    Participar en el Fortalecimiento Institucional de los Gobiernos tanto Nacional
                    como estatales y municipales, con el propósito de contribuir al desarrollo
                    sustentable del país para Desarrollar Proyectos de Obras y Servicios
                    Públicos o Privados y en Infraestructura.
                  </Typography>
                </Grid>
              </Card>
            </TabPanel2>

            <TabPanel2 value={valueTabOne} index={1}>
              <Card variant="outlined" sx={{  backgroundColor: 'transparent', border: 0 }}>
                <div className='backTwo'></div>
                <Grid style={{ backgroundColor: '#292929', marginTop: -90, marginLeft: 30, position: 'absolute', zIndex: 1100, width: 250 }}>
                  <Typography fontSize={10} fontWeight={400} variant="body2" color="white" padding={2}>
                    Ser líderes Nacionales en:
                    Promoción del desarrollo económico sustentable.
                    Calidad de vida.
                    Empleos permanentes con ingresos bien remunerados.
                    Infraestructura Industrial y Comercial de primer mundo.
                    Ser una empresa competitiva y altamente productiva.
                    El mejor clima de negocios.
                    Polo de atracción de inversiones.
                    Presencia nacional e internacional.
                  </Typography>
                </Grid>
              </Card>
            </TabPanel2>

            <TabPanel2 value={valueTabOne} index={2}>
              <Card variant="outlined" sx={{  backgroundColor: 'transparent', border: 0 }}>
                <div className='backTwo'></div>
                <Grid style={{ backgroundColor: '#292929', marginTop: -90, marginLeft: 30, position: 'absolute', zIndex: 1100, width: 250 }}>
                  <Typography fontSize={10} fontWeight={400} variant="body2" color="white" padding={2}>
                    Satisfacer los requerimientos y especificaciones técnicas de nuestros clientes
                    precios competitivos.
                    ofreciendo
                    • Ejecutar los trabajos con lo recursos humanos, materiales y tecnología adecuada,
                    cumpliendo con la planificación establecida con nuestros clientes.
                    • Responder a las necesidades de nuestros clientes en el menor tiempo posible.
                    • Cumplir con Proyectos de Infraestructura y Servicios Públicos a los Gobiernos Estatales,
                    Municipales y sus Organismos, Empresas Privadas y Publicas.
                  </Typography>
                </Grid>
              </Card>
            </TabPanel2>

            <Tabs
              orientation="horizontal"
              variant="scrollable"
              value={valueTabOne}
              onChange={(event, newValue) => {
                setValueTabOne(newValue);
              }}
              aria-label="Vertical tabs example"
              sx={{ marginTop: 5 }}
            >
              <Tab sx={{ fontSize: 10, fontWeight: '600' }} label="MISIÓN" {...a12yProps(0)} />
              <Tab sx={{ fontSize: 10, fontWeight: '600' }} label="VISIÓN" {...a12yProps(1)} />
              <Tab sx={{ fontSize: 10, fontWeight: '600' }} label="OBJETIVOS" {...a12yProps(2)} />
            </Tabs>
          </Box>
          
        </Grid>

        {/* esta tab es para pantallas de 769 a 919 */}
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          className='hideElements hideElementsForTablets' 
          md={10} lg={10} xl={12} 
        >
            <Grid className='styleForTitle'>
              
              <div>ENVIRONMENT </div>
              <div>DRILLING  </div>
              <div>SERVICES </div>
             
            </Grid>

            <Grid sx={{ marginTop: 5 }}>

              <TabPanel2 value={valueTabOne} index={0}>
                <Card variant="outlined" sx={{ backgroundColor: 'transparent', border: 0 }}>
                  <div className='backTwo'></div>
                  <div style={{ backgroundColor: '#292929', marginTop: '-22vh', marginLeft: '10vh', position: 'absolute', zIndex: 1100, width: 300 }}>
                    <Typography fontSize={12} fontWeight={400} variant="body2" color="white" padding={2}>
                      Participar en el Fortalecimiento Institucional de los Gobiernos tanto Nacional
                      como estatales y municipales, con el propósito de contribuir al desarrollo
                      sustentable del país para Desarrollar Proyectos de Obras y Servicios
                      Públicos o Privados y en Infraestructura.
                    </Typography>
                  </div>
                </Card>
              </TabPanel2>

              <TabPanel2 value={valueTabOne} index={1}>
                <Card variant="outlined" sx={{  backgroundColor: 'transparent', border: 0 }}>
                  <div className='backTwo'></div>
                  <div style={{ backgroundColor: '#292929', marginTop: '-24vh', marginLeft: '6vh', position: 'absolute', zIndex: 1100, width: 350 }}>
                    <Typography fontSize={12} fontWeight={400} variant="body2" color="white" padding={2}>
                      Ser líderes Nacionales en:
                      Promoción del desarrollo económico sustentable.
                      Calidad de vida.
                      Empleos permanentes con ingresos bien remunerados.
                      Infraestructura Industrial y Comercial de primer mundo.
                      Ser una empresa competitiva y altamente productiva.
                      El mejor clima de negocios.
                      Polo de atracción de inversiones.
                      Presencia nacional e internacional.
                    </Typography>
                  </div>
                </Card>
              </TabPanel2>

              <TabPanel2 value={valueTabOne} index={2}>
                <Card variant="outlined" sx={{  backgroundColor: 'transparent', border: 0 }}>
                  <div className='backTwo'></div>
                  <div style={{ backgroundColor: '#292929', marginTop: '-29vh', marginLeft: '6vh', position: 'absolute', zIndex: 1100, width: 350 }}>
                    <Typography fontSize={12} fontWeight={400} variant="body2" color="white" padding={2}>
                      Satisfacer los requerimientos y especificaciones técnicas de nuestros clientes
                      precios competitivos.
                      ofreciendo
                      • Ejecutar los trabajos con lo recursos humanos, materiales y tecnología adecuada,
                      cumpliendo con la planificación establecida con nuestros clientes.
                      • Responder a las necesidades de nuestros clientes en el menor tiempo posible.
                      • Cumplir con Proyectos de Infraestructura y Servicios Públicos a los Gobiernos Estatales,
                      Municipales y sus Organismos, Empresas Privadas y Publicas.
                    </Typography>
                  </div>
                </Card>
              </TabPanel2>

            </Grid>

            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={valueTabOne}
              onChange={(event, newValue) => {
                setValueTabOne(newValue);
              }}
              aria-label="Vertical tabs example"
              sx={{ marginTop: 15, marginLeft: 15 }}
            >
              <Tab sx={{ fontSize: 15, fontWeight: '400' }} label="MISIÓN" {...a12yProps(0)} />
              <Tab sx={{ fontSize: 15, fontWeight: '400' }} label="VISIÓN" {...a12yProps(1)} />
              <Tab sx={{ fontSize: 15, fontWeight: '400' }} label="OBJETIVOS" {...a12yProps(2)} />
            </Tabs>
        </Grid>

        {/* esta grip para tabs de politicas pantallas mas peque;os de 600px */}
        <Grid className='tabPoliticas' xs={12} md={12} lg={12} xl={12}>
          <div id="otro-elemento"></div>
          <Box sx={{ backgroundColor: 'white', padding: 0, marginTop: 20 }}>
            <Box>
              <Tabs indicatorColor="secondary" sx={{ height: 170 }} variant="fullWidth" value={valuePoliticas} onChange={(event, newValue) => {
            setValuePoliticas(newValue);
          }} aria-label="basic tabs example">
                <Tab sx={{ marginTop: 5, fontWeight: '700', fontSize: 10, lineHeight: '18px', color: 'black' }} label="POLITICA DE CALIDADD" {...a11yProps(0)} />
                <Tab sx={{ marginTop: 5, fontWeight: '700', fontSize: 10, lineHeight: '18px', color: 'black' }} label="POLITICAS S.H.A" {...a11yProps(1)} />
                <Tab sx={{ marginTop: 5, fontWeight: '700', fontSize: 10, lineHeight: '18px', color: 'black' }} label="POLITICA AMBIENTAL" {...a11yProps(2)} />
              </Tabs>
            </Box>
          </Box>

          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box sx={{ marginTop: -12, width: '100%' }}>
              <TabPanel value={valuePoliticas} index={0}>
                <Grid style={{ padding: 20, backgroundColor: '#292929', color: 'white',  }}>
                  <span style={{ fontSize: 13, }}>
                    <strong>ENVIRONMENT DRILLING SERVICES, C.A</strong> se ha establecido como política y norma un proceso de mejoramiento continuo con el fin
                    de asegurar la calidad de nuestros servicios y procesos productivos, manteniendo en todo momento la competitividad, mediante un alto
                    grado de participación para lograr satisfacer las necesidades de nuestros clientes, con el respeto y cordial confianza que
                    merecen, trabajando en conjunto con los proveedores para garantizar la calidad, tiempo y entrega completa de nuestros servicios,
                    logrando cumplir con los requerimientos y metas exigidas, y así desarrollar tecnológicamente nuestros procesos de acuerdo con la
                    tendencia del mercado.
                    <br />
                    <strong>ENVIRONMENT DRILLING SERVICES, C.A</strong> se encuentra comprometida en:
                    <br />
                    • Velar por el cumplimientos de las normas de seguridad y conservación ambiental
                    <br />
                    • Mantener un proceso de mejoramiento continuo, buscando así la misma productividad y rentabilidad en nuestra empresa y un
                    servicio de excelencia para nuestros clientes.
                    <br />
                    • Mantener un alto nivel de responsabilidad con la evaluación y selección de nuestros proveedores que garanticen la calidad y
                    suministro a tiempo de los materiales e insumos que se requieran para la ejecución de un contrato.
                  </span>
                </Grid>
                <Grid style={{ backgroundColor: '#FE100F', height: 30 }}></Grid>
              </TabPanel>
              <TabPanel value={valuePoliticas} index={1}>
                <Grid style={{ padding: 20, backgroundColor: '#292929', color: 'white' }}>
                  <span style={{ fontSize: 13, }}>
                    La <strong>Empresa ENVIRONMENT DRILLING SERVICES, C.A</strong> , tiene como objetivo dar a conocer la política de seguridad
                    industrial ambiente e higiene ocupacional la cual se encuentra orientada a proteger y prevenir a sus trabajadores y
                    trabajadoras de los accidentes y enfermedades ocupacionales a los cuales están expuestos; los
                    determinada obra o servicio por puesto de trabajo e instalación.
                    <br />
                    <strong>ENVIRONMENT DRILLING SERVICES, C.A.</strong> velara y acatara el cumplimiento de todas las leyes,
                    normativas vigentes nacionales e internacionales en materia de SIAHO, para garantizar la integridad
                    primeros para
                    reglamentos y
                    física y mental
                    de sus trabajadores y trabajadoras, garantizando en todas sus actividades el derecho y el deber de cada
                    generación, proteger y mantener el ambiente en beneficio de si misma y del mundo futuro, de tal forma que
                    podamos cumplir con el derecho individual y colectivo de disfrutar de una vida y de un ambiente seguro, sano y
                    ecológicamente equilibrado.
                  </span>
                </Grid>
                <Grid style={{ backgroundColor: '#FE100F', height: 30 }}></Grid>
              </TabPanel>
              <TabPanel value={valuePoliticas} index={2}>
                <Grid style={{ padding: 20, backgroundColor: '#292929', color: 'white' }}>
                  <span style={{ fontSize: 13, }}>
                    La política ambiental de <strong>ENVIRONMENT DRILLIN SERVICES, C.A,</strong> empresa dedicada al ramo de la perforación, reparación
                    y servicios de pozos, transporte y obras de arquitectura, mantenimiento, construcción civil y construcción mecánica ,
                    comprometido con la preservación del medio ambiente, tiene como objetivo alcanzar los máximos estándares en el
                    desempeño ambiental, como base para lograr un desarrollo sustentable en todas sus operaciones, en relación con sus
                    empleados, la comunidad y las generaciones futuras. Por ello se adopta el compromiso de desarrollar un sistema productivo
                    integrado y eco-eficiente con un alto nivel de desempeño y mejora continua.
                    
                  </span>
                </Grid>
                <Grid style={{ backgroundColor: '#FE100F', height: 30 }}></Grid>
              </TabPanel>
            </Box>
          </Grid>

        </Grid>

        {/* esta grip para titulo de servicios mas peque;os de 600px */}
        <div id="servicio"></div>
        <Grid xs={12} md={11} lg={11} xl={11.5} 
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid sx={{ marginTop: 15 }}>
            <Typography style={{ fontWeight: '700', fontSize: 22, lineHeight: '41.02px' }}>NUESTROS SERVICIOS</Typography>
          </Grid>
        </Grid>
        
        { /**  Cards de servicios para todos los dispositivos */ }
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          rowSpacing={2}
          columnSpacing={2}
          marginTop={5}
          marginBottom={15}
          xs={10} sm={10} md={8} lg={10}
        >

          <Grid item xs={10} sm={6} md={6} lg={3}>
            <Grid container direction="column" justifyContent="center">
              <Card>
                <CardContent>
                  <Typography sx={{ fontSize: 16, textAlign: 'center' }} fontWeight={700} color="black">
                    Servicios pozos de forma integral sub suelo - superficie
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid item xs={10} sm={6} md={6} lg={3}>
            <Grid container direction="column" justifyContent="center">
              <Card>
                <CardContent>
                  <Typography sx={{ fontSize: 16, textAlign: 'center' }} fontWeight={700} color="black">
                    Servicios de operacion y mantenimiento de equipos
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid item xs={10} sm={6} md={6} lg={3}>
            <Grid container direction="column" justifyContent="center">
              <Card>
                <CardContent>
                  <Typography sx={{ fontSize: 16, textAlign: 'center' }} fontWeight={700} color="black">
                    Servicios de operacion y mantenimiento de equipos
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid item xs={10} sm={6} md={6} lg={3}>
            <Grid container direction="column" justifyContent="center">
              <Card>
                <CardContent>
                  <Typography sx={{ fontSize: 16, textAlign: 'center' }} fontWeight={700} color="black">
                    Servicios de operacion y mantenimiento de equipos
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid item xs={10} sm={6} md={6} lg={3}>
            <Grid container direction="column" justifyContent="center">
              <Card>
                <CardContent>
                  <Typography sx={{ fontSize: 16, textAlign: 'center' }} fontWeight={700} color="black">
                    Servicios pozos de forma integral sub suelo - superficie
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid item xs={10} sm={6} md={6} lg={3}>
            <Grid container direction="column" justifyContent="center">
              <Card>
                <CardContent>
                  <Typography sx={{ fontSize: 16, textAlign: 'center' }} fontWeight={700} color="black">
                    Servicios de operacion y mantenimiento de equipos
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid item xs={10} sm={6} md={6} lg={3}>
            <Grid container direction="column" justifyContent="center">
              <Card>
                <CardContent>
                  <Typography sx={{ fontSize: 16, textAlign: 'center' }} fontWeight={700} color="black">
                    Servicios de operacion y mantenimiento de equipos                    
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid item xs={10} sm={6} md={6} lg={3}>
            <Grid container direction="column" justifyContent="center">
              <Card>
                <CardContent>
                  <Typography sx={{ fontSize: 16, textAlign: 'center' }} fontWeight={700} color="black">
                    Servicios de operacion y mantenimiento de equipos
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

        </Grid>

        {/* esta grip para titulo de servicios mas peque;os de 600px */}
        <Grid xs={12} md={12} lg={12} xl={12} 
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ backgroundColor: 'white' }}
        >

          <Grid
            container
            direction="column"
            alignItems="center"
            marginTop={10}
            marginBottom={-30}
            xs={10} sm={12} md={10} lg={10} xl={12} 
          >

            <Grid sx={{ marginTop: 1, marginBottom: 4}}>
              <Typography style={{ fontWeight: '700', fontSize: 22, lineHeight: '41.02px' }}>EQUIPOS</Typography>
            </Grid>

            <Slider {...settings}>
              <div>
                <img src={equip1} width={280} height={500} />
              </div> 

              <div>
                <img src={equip2} width={280} height={500} />
              </div> 

              <div>
                <img src={equip3} width={280} height={500} />
              </div> 

              <div>
                <img src={equip4} width={280} height={500} />
              </div> 

              <div>
                <img src={equip5} width={280} height={500} />
              </div> 

              <div>
                <img src={equip6} width={280} height={500} />
              </div>  

              <div>
                <img src={equip7} width={280} height={500} />
              </div>   

              <div>
                <img src={equip8} width={280} height={500} />
              </div> 

            </Slider>
          </Grid>
        </Grid>

        {/* esta grip es para formulario mas peque;os de 600px */}
        <div id="contacto"></div>
        {/* eslint-disable-next-line */}
        {/* @ts-ignore  */ }
        <form ref={form} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '40vh' }} >
          <Grid className='forTablets' xs={10} sm={10} md={8} lg={8} xl={10} 
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ backgroundColor: 'white', marginTop: 10 }}
            rowSpacing={2}
            columnSpacing={2}
          >

            <Grid item xs={12} lg={12}>
              <Grid container direction="row" justifyContent="center">
                <Typography style={{ fontWeight: '700', fontSize: 15, lineHeight: '41.02px', margin: 20 }}>CONTACTANOS</Typography>
              </Grid>
            </Grid>
          
          
            <Grid item xs={10} md={5} lg={5}>
              <Grid container direction="column" justifyContent="center">
                <Controller
                    name="nombre"
                    control={control}
                    rules={{ 
                      required: true 
                    }}
                    render={() => 
                      <>
                        <TextField 
                          sx={{ backgroundColor: '#E0E4E5' }}
                          id="standard-basic"
                          label="Nombre"
                          variant="outlined"
                          {...register("nombre")}
                        />
                        <p>{errors?.nombre?.message}</p>
                      </>
                    }
                  />
              </Grid>
            </Grid>

            <Grid item xs={10} md={5} lg={5}>
              <Grid container direction="column" justifyContent="center">
                <Controller
                  name="apellido"
                  control={control}
                  rules={{ 
                    required: true 
                  }}
                  render={() => 
                    <>
                      <TextField 
                        sx={{ backgroundColor: '#E0E4E5' }}
                        id="standard-basic"
                        label="Apellido"
                        variant="outlined"
                        {...register("apellido")}
                      />
                      <p>{errors?.apellido?.message}</p>
                    </>
                  }
                />
              </Grid>
            </Grid>

            <Grid item xs={10} md={5} lg={5}>
              <Grid container direction="column" justifyContent="center">
                <Controller
                  name="telefono"
                  control={control}
                  rules={{ 
                    required: true 
                  }}
                  render={() => 
                    <>
                      <TextField 
                        sx={{ backgroundColor: '#E0E4E5' }}
                        id="standard-basic"
                        label="Telefono"
                        variant="outlined"
                        {...register("telefono")}
                      />
                      <p>{errors?.telefono?.message}</p>
                    </>
                  }
                />
              </Grid>
            </Grid>

            <Grid item xs={10} md={5} lg={5}>
              <Grid container direction="column" justifyContent="center">
                <Controller
                  name="email"
                  control={control}
                  rules={{ 
                    required: true 
                  }}
                  render={() => 
                    <>
                      <TextField 
                        sx={{ backgroundColor: '#E0E4E5' }}
                        id="standard-basic"
                        label="Correo"
                        variant="outlined"
                        {...register("email")}
                      />
                      <p>{errors?.email?.message}</p>
                    </>
                  }
                />
              </Grid>
            </Grid>

            <Grid item xs={10} lg={10}>
              <Grid container direction="column" justifyContent="center">
                <Controller
                  name="mensaje"
                  control={control}
                  rules={{ 
                    required: true 
                  }}
                  render={() => 
                    <>
                      <TextField 
                        sx={{ backgroundColor: '#E0E4E5' }}
                        id="standard-basic"
                        label="Mensaje"
                        variant="outlined"
                        multiline
                        maxRows={6}
                        {...register("mensaje")}
                      />
                        <p>{errors?.mensaje?.message}</p>
                      </>
                    }
                  />
                </Grid>
            </Grid>

            {
              showAlert ? (
                <Alert
                  style={{ position: 'absolute', zIndex: 1100 }}
                  severity="success" 
                  onClose={() => setShowAlert(false)}
                >
                  <AlertTitle>Mensaje enviado!</AlertTitle>
                </Alert>
              ) : null
            }

            <Grid item lg={3}>
              <Grid container direction="row" justifyContent="center">
                <Button onClick={handleSubmit(onSubmit)} sx={{ backgroundColor: '#FE100F', width: 197, height: 42, marginBottom: 5, borderRadius: 20, marginTop: 4 }} variant="contained">Enviar</Button>
              </Grid>
            </Grid>
            
          </Grid>
        </form>
          
        <div id="empresa"></div>
        <Grid id='forTablets' xs={12} sm={12} md={12} lg={12} xl={12} 
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ backgroundColor: '#292929', position: 'relative', zIndex: -10, marginTop: -20 }}
        >

          <Grid item md={12} lg={12}>
            <Grid container direction="row" justifyContent="center">
              <div style={{ backgroundColor: 'white', margin: 50, marginTop: '35vh', marginBottom: '4%' }}>
                <div className='logoEnv'></div>
              </div>
            </Grid>
          </Grid>

          <Grid item xs={10} md={6} lg={6}>
            <Grid container direction="column" justifyContent="center">
              <Typography style={{ color: 'white', lineHeight: 2.5, fontSize: 10 }}>
                <strong>EMPRESA: ENVIRONMENT DRILLING SERVICES, C.A.</strong>
                <br />
                RIF: J- 40425603-2 
                <br />
                <strong>DIRECCIÓN FISCAL:</strong> CIUDAD OJEDA, CARRETERA N, ENTRE AVENIDA 41 Y 42
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={10} md={3} lg={3}>
            <Grid container direction="column" justifyContent="center">
              <Typography style={{ color: 'white', lineHeight: 2.5, fontSize: 10 }}>
                Direccion principal: Centro Comercial Ciudad Tamanaco (CCCT)
                Avenida La Estancia, C/C C. Ernesto Blohm, Torre C piso 5, Of. 506 CHUAO 1090, Miranda
                <br />
                Direccion secundaria: EN CIUDAD OJEDA OCCIDENTE ESTADO ZULIA OFICINA PRINCIPAL CARRETERA LARA ZULIA.
                <br />
                <strong>BASE:</strong> AV. PEÑALVER, GALPÓN ENVIRONMENT DRILLING, EL TIGRE, EDO. ANZOÁTEGUI.
                <br />
                CÓDIGO SAP PDVSA 100176645
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={10} lg={12}>
            <Grid container direction="row" justifyContent="center">
              <Typography style={{ fontWeight: '400', fontSize: 10, lineHeight: '23.02px', color: 'white', marginTop: 50 }}>gerencia@environmentdrilling.com</Typography>
            </Grid>
          </Grid>

          <Grid item lg={12}>
            <Grid container direction="row" justifyContent="center">
              <Typography style={{ fontWeight: '400', fontSize: 10, lineHeight: '23.02px', color: 'white', margin: 30 }}>
                Telefonos:
                0212-9591428, 0212-9593320, 0212-9591467, 0212-959603, 0212-9599004, 0212-9595948
              </Typography>
            </Grid>
          </Grid>

          <div style={{ backgroundColor: '#FE100F', height: 60, width: '100%' }}>
            <Grid item lg={12}>
              <Grid container direction="row" justifyContent="center">
                <Typography style={{ fontWeight: '700', fontSize: 10, lineHeight: '23.02px', color: 'white', marginTop: 15 }}>ENVIRONMENT DRILLING SERVICES, C.A. </Typography>
              </Grid>
            </Grid>
          </div>
          
        </Grid>

      </Grid>
      
    </main>
  )
}
